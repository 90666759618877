 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			
			<el-form-item class="el_form_item" label="用户电话">
				<el-input class="el_input" v-model="form.user_tel" placeholder="用户电话搜索" clearable></el-input>
			</el-form-item>
			
			<el-form-item class="el_form_item" label="用户姓名">
				<el-input class="el_input" v-model="form.user_name" placeholder="用户姓名搜索" clearable></el-input>
			</el-form-item>

			<el-form-item class="el_form_item" label="对数网点">
				<el-select class="el_input" v-model="form.outlets_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option
						v-for="(item,index) in outlets_list.list"
						:key="index"
						:label="item.name"
						:value="item.outlets_num"
					></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item class="el_form_item" label="用户类型">
				<el-select class="el_input" v-model="form.type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="网点负责人" value="1"></el-option>
					<el-option label="网点调度" value="2"></el-option>
					<el-option label="其他员工" value="3"></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item class="el_form_item" label="用户状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加时间/用户编号">
					<template slot-scope="scope">
						<div>(创建时间) {{scope.row.creat_time_text}}</div>
						<div>(用户编号) {{scope.row.user_num}}</div>
						<div>(网点编号) {{scope.row.outlets_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="网点信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(网点名称) {{outlets_list.buf[scope.row.outlets_num]?outlets_list.buf[scope.row.outlets_num].name:""}}</div>
						<div class="tab_line_item">
							(省/市/区) 
							{{outlets_list.buf[scope.row.outlets_num]?outlets_list.buf[scope.row.outlets_num].prov:""}} / 
							{{outlets_list.buf[scope.row.outlets_num]?outlets_list.buf[scope.row.outlets_num].city:""}} / 
							{{outlets_list.buf[scope.row.outlets_num]?outlets_list.buf[scope.row.outlets_num].county:""}}
						</div>
						<div class="tab_line_item">
							(详细地址) 
							{{outlets_list.buf[scope.row.outlets_num]?outlets_list.buf[scope.row.outlets_num].addr:""}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="用户信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名){{scope.row.name}}</div>
						<div class="tab_line_item">(电话){{scope.row.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="类型/状态/备注">
					<template slot-scope="scope">
						<div class="tab_line_item">(类型) {{scope.row.type_text}}</div>
						<div class="tab_line_item">(状态) {{scope.row.status_text}}</div>
						<div class="tab_line_item">(备注) {{scope.row.mark}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="190">
					<template slot-scope="scope">
						<el-button @click="open_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 添加弹出层 -->
		<userAdd :outlets_list="outlets_list.list" :is_show="add.is_show" @sub="add_sub"></userAdd>

		<!-- 修改弹出层 -->
		<userEdit :outlets_list="outlets_list.list" :is_show="edit.is_show" :old_data="edit.old_data" @sub="edit_sub"></userEdit>

	</div>
</template>

<script>
	import userAdd from './add.vue';
	import userEdit from './edit.vue';
	import {regionDataPlus,CodeToText} from 'element-china-area-data'
	export default {
		components:{
			userAdd,
			userEdit
		},
		data() {
			return {

				//省市县基础数据
				cities:regionDataPlus,

				//搜索条件
				form: {
					user_tel:'',//用户电话
					user_name:'',//用户姓名
					type:'',//类型(1:网点负责人,2:网点调度,3:其他)
					status:'',//状态(1:正常,2:停用)
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//网点列表
				outlets_list:{

					//是否读取过
					is_readed:false,

					//原始结果集
					list:[],

					//整理过的
					buf:{},
				},
				
				//添加界面
				add:{

					//是否显示
					is_show:0
				},

				edit:{

					//是否显示
					is_show:0,

					//旧数据
					old_data:{}
				},
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()

			//读取客户数据
			this.$my.net.req({
				data:{
					mod:'truck_business_outlets',
					ctr:'outlets_list_admin',
					p:1,
					num:100000,
				},
				callback:(data)=>{

					//已读取过
					this.outlets_list.is_readed=true;

					//置入结果集
					let buf={};
					for(var item of data.list){
						buf[item.outlets_num]={...item}
					}
					this.outlets_list.buf=buf
					this.outlets_list.list=data.list
				}
			})
		},
		methods: {

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'删除用户",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_business_outlets',
								ctr:'user_del_admin',
								user_num:item.user_num
							},
							callback:(data)=>{
								
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
			
			//打开客户添加界面
			open_add_view(){

				//如果已读取
				if(this.outlets_list.is_readed){

					//如果数据为空
					if(this.outlets_list.list.length==0){
						this.$my.other.msg({
							type:'warning',
							str:"网点数据为空,请先添加客户数据"
						});
						return;
					}else{

						//打开界面
						this.add.is_show++;
					}
				}else{

					this.$my.other.msg({
						type:'warning',
						str:"网点数据未读取完成, 请稍后或者刷新后再试"
					});
					return;
				}
			},
			add_sub(form){//添加
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"添加成功"
				});

				//关闭页面
				this.add.is_show=0
				
				//刷新页面
				this.get_page_data()
			},

			//打开客户修改界面
			open_edit_view(item){

				//置入数据
				this.edit.old_data=item

				//打开界面
				this.edit.is_show++;
			},
			edit_sub(form){
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"修改成功"
				});

				//关闭页面
				this.edit.is_show=0

				//读取页面数据
				this.get_page_data()
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					user_tel:'',//用户电话
					user_name:'',//用户姓名
					type:'',//类型(1:网点负责人,2:网点调度,3:其他)
					status:'',//状态(1:正常,2:停用)
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'user_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//用户状态(1:正常,2:否停用)
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}

							//类型(1:网点负责人,2:网点调度,3:其他)
							switch(item.type){
								case '1':item.type_text="网点负责人";break;
								case '2':item.type_text="网点调度";break;
								case '3':item.type_text="其他员工";break;
							}
						}
						
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>